import { Box, Typography } from "@mui/material";

export default function Quote({ block }){

    return(
        <Box>
            <Typography variant='p' component='p'
                sx={{
                    fontSize:{
                        xs:'1.6rem',
                        md:'2rem'
                    },
                    fontWeight:'bold'
                }}
            >
                "{block.quote}"
            </Typography>
            <Box
                sx={{
                    mt:'2rem',
                    display:'flex',
                    flexDirection:{
                        xs:'column',
                        md:'row'
                    },
                    alignItems:'center'
                }}
            >
                <Box
                    sx={{
                        height:'5rem',
                        width:'5rem',
                        borderRadius:'5rem',
                        backgroundImage:'url('+process.env.REACT_APP_BACKEND+block.person_image.data?.attributes?.url+')',
                        backgroundSize:'cover',
                        backgroundPosition:'center',
                        mr:'1.5rem',
                        mb:{
                            xs:'1rem',
                            md:0
                        }
                    }}
                />
                <Box
                    sx={{
                        textAlign:{
                            xs:'center',
                            md:'left'
                        }
                    }}
                >
                    <Typography variant='h6' component='p'
                        sx={{
                            fontWeight:'bold'
                        }}
                    >
                        {block.person_name},
                    </Typography>
                    <Typography variant='h6' component='p'
                        sx={{
                            color:theme=>theme.palette.yellow,
                            fontWeight:'bold'
                        }}
                    >
                        {block.person_title}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}