import { Box } from '@mui/material';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';

export default function RichText({ block }){

    const [content, setContent]=useState();

    //Replace image srcs
    useEffect(()=>{
        if(block){
            const parser = new DOMParser();
            const tempContent = parser.parseFromString(block.content, 'text/html');
            const images = tempContent.querySelectorAll('img');
            images.forEach((img)=>{
                const oldSrc=img.getAttribute('src');
                img.src=process.env.REACT_APP_BACKEND+oldSrc;
            });
            setContent(tempContent.body.innerHTML); 
        }
    },[block]);

    return(
        <Box
            className='html-section'
        >
            {content?
                parse(content)
            :
                null
            }
        </Box>
    );
}