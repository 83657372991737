import { Collapse, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState } from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import parse from 'html-react-parser';

export default function QAs({block}){

    const mobile=useMediaQuery(theme=>theme.breakpoints.down('md'));

    const [currentFAQ,setCurrentFAQ]=useState(999);

    return(
        <Box
            sx={{
                color:theme=>theme.palette.blue
            }}
        >
            <Typography variant={mobile? 'h4' : 'h3'} component='h3'
                sx={{
                    mb:'3rem'
                }}
            >
                {block.heading}
            </Typography>
            {block?.content?.map((faq, i)=>{
                return(
                    <Fragment
                        key={faq.id}
                    >
                        <Box
                            onClick={()=>{
                                if(currentFAQ===i){
                                    setCurrentFAQ(999);
                                }
                                else{
                                    setCurrentFAQ(i);
                                }
                            }}
                            sx={{
                                m:'1.5rem 0',
                                width:'fit-content',
                                display:'flex',
                                alignItems:'center',
                                cursor:'pointer'
                            }}
                        >
                            <AddCircleIcon 
                                fontSize={ mobile? 'medium' : 'large' }
                                sx={{
                                    height:'2rem',
                                    width:'2rem',
                                    color:theme=>theme.palette.yellow,
                                    m:'0 1rem 0 0',
                                    rotate: currentFAQ===i? '45deg' : 0,
                                    transition: 'rotate 0.25s'
                                }}
                            />
                            <Typography 
                                variant={ mobile? 'h6' : 'h4' } 
                                component='h4'
                                sx={{
                                    fontWeight:'bold'
                                }}
                            >
                                {faq.question}
                            </Typography>
                        </Box>
                        <Collapse in={currentFAQ===i}>
                            <Box
                                className='html-section'
                                sx={{
                                    m:'0 0 2rem 3rem'
                                }}
                            >
                                {parse(faq.answer)}
                            </Box>
                        </Collapse>
                    </Fragment>
                );
            })}
        </Box>
    );
}