import { Collapse, Container, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import parse from 'html-react-parser';

function ServicesHeader({pageData}){

    const mobile=useMediaQuery(theme=>theme.breakpoints.down('md'));

    const [currentFAQ,setCurrentFAQ]=useState(999);

    return(
        <Container 
            maxWidth='xl'
            className="dm-section"
            data-color='#FFCD00'
        >
            <Box
                sx={{
                    p: mobile ? '6rem 0 4rem 0' : '8rem 0',
                    color:theme=>theme.palette.blue
                }}
            >
                {pageData &&
                    <>
                        <Typography variant={mobile? 'h4' : 'h3'} component='h3'
                            sx={{
                                mb:'3rem'
                            }}
                        >
                            Frequently Asked Questions 
                        </Typography>
                        {Object.values(pageData.faqs).map((faq, i)=>{
                            return(
                                <>
                                    <Box
                                        onClick={()=>{
                                            if(currentFAQ===i){
                                                setCurrentFAQ(999);
                                            }
                                            else{
                                                setCurrentFAQ(i);
                                            }
                                        }}
                                        sx={{
                                            m:'1.5rem 0',
                                            width:'fit-content',
                                            display:'flex',
                                            alignItems:'center',
                                            cursor:'pointer'
                                        }}
                                    >
                                        <AddCircleIcon 
                                            fontSize={ mobile? 'medium' : 'large' }
                                            sx={{
                                                height:'2rem',
                                                width:'2rem',
                                                color:theme=>theme.palette.yellow,
                                                m:'0 1rem 0 0',
                                                rotate: currentFAQ===i? '45deg' : 0,
                                                transition: 'rotate 0.25s'
                                            }}
                                        />
                                        <Typography 
                                            variant={ mobile? 'h6' : 'h4' } 
                                            component='h4'
                                            sx={{
                                                fontWeight:'bold'
                                            }}
                                        >
                                            {faq.question}
                                        </Typography>
                                    </Box>
                                    <Collapse in={currentFAQ===i}>
                                        <Box
                                            className='html-section'
                                            sx={{
                                                m:'0 0 2rem 3rem'
                                            }}
                                        >
                                            {parse(faq.answer)}
                                        </Box>
                                    </Collapse>
                                </>
                            );
                        })}
                    </>
                }
            </Box>
        </Container>
    )
}

export default ServicesHeader;